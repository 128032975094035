.canvas {
  position: absolute;
  box-sizing: border-box;
  z-index: 10;
  pointer-events: none;
}

div.parent {
  top: 0;
  left: 0;
  position: absolute;
  pointer-events: none;
}
