.react-video-wrap {
  padding-bottom: 36px;
  position: relative;
}

.react-video-wrap video {
  background-color: #000;
  display: block;
  height: 100%;
  width: 100%;
}

.react-video-controls {
  background-color: #e7e7e7;
  display: flex;
  height: 36px;
  padding: 0 7px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.react-video-controls button {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  border: 0;
  cursor: pointer;
  margin: 10px 7px;
  padding: 0;
  outline: none;
  height: 16px;
  width: 16px;
  text-indent: -9999px;
}

.react-video-controls button.btn-icon {
  text-indent: initial;
}

.react-video-controls button.btn-icon:disabled {
  cursor: not-allowed;
}

.react-video-controls .play {
  background-image: url('./images/play-black.svg');
}

.react-video-controls .pause {
  background-image: url('./images/pause-black.svg');
  background-size: 15px;
}

.react-video-controls .talking {
  background-image: url('./images/talking.svg');
}

.react-video-controls .muted {
  background-image: url('./images/muted.svg');
  background-size: 15px;
}

.react-video-controls .siren {
  background-image: url('./images/siren.svg');
  background-color: red;
  /* width: 20px;
  height: 20px;
  margin-top: 6px; */
  /* background-size: 15px; */
}

.react-video-controls .no-siren {
  background-image: url('./images/no-siren.svg');
  /* width: 20px;
  height: 20px;
  margin-top: 6px; */
  /* background-size: 15px; */
}

.react-video-controls .volume {
  background-image: url('./images/volume-black.svg');
  background-position-x: 0;
  width: 17px;
}

.react-video-controls .no-volume {
  background-image: url('./images/no-volume-black.svg');
  background-position-x: 0;
  width: 17px;
  cursor: pointer;
}

.react-video-controls .cross-line {
  position: absolute;
  width: 100%;
  height: 3px;
  background: #ea3423;
  cursor: pointer;
  transform: rotate(-45deg);
  margin-top: 16px;
}

.volume-tooltip-text {
  color: black;
  font-size: 12px;
}

.react-video-controls .full-screen {
  background-image: url('./images/full-screen-black.svg');
  width: 17px;
}

.react-video-controls .reload {
  background-image: url('./images/reload.svg');
  width: 17px;
}

.react-video-controls .time {
  color: #969696;
  font-size: 10px;
  line-height: 37px;
  margin: 0 7px;
}

.react-video-controls progress {
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.react-video-controls .progress-wrap {
  flex-grow: 1;
  margin: 5px 7px 0;
  position: relative;
}

.react-video-controls .progress-wrap progress {
  height: 8px;
  width: 100%;
}

.react-video-controls .progress-wrap progress::-webkit-progress-value {
  background: #28c0f0;
}

.react-video-controls .progress-wrap progress::-webkit-progress-bar {
  background: #fff;
}

.react-video-controls .progress-wrap .react-video-marker {
  background-color: #666;
  cursor: pointer;
  display: block;
  height: 8px;
  width: 4px;
  position: absolute;
  top: 9px;
  left: 50%;
}

.react-video-controls .volume-wrap {
  position: relative;
}

.react-video-controls .volume-wrap:hover progress {
  display: block;
}

.react-video-controls .volume-wrap progress {
  border-radius: 2px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: none;
  height: 12px;
  overflow: hidden;
  position: absolute;
  top: -52px;
  left: -31px;
  transform: rotate(-90deg);
  width: 100px;
}

.react-video-controls .volume-wrap progress::-webkit-progress-value {
  background: #fff;
}

.react-video-controls .volume-wrap progress::-webkit-progress-bar {
  background: #9d9d9d;
}

.react-video-full-screen .react-video-wrap {
  background-color: #000;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.react-video-full-screen .react-video-player {
  height: calc(100vh - 36px);
  max-width: 100%;
}

.react-video-full-screen .react-video-close {
  background: url('./images/close-video.svg') no-repeat transparent;
  border: 0;
  cursor: pointer;
  opacity: 1;
  outline: none;
  text-indent: -9999px;
  height: 23px;
  width: 23px;
  position: absolute;
  top: 36px;
  right: 77px;
}

.react-video-full-screen .react-video-controls {
  background-color: #595959;
}

.react-video-full-screen .react-video-controls .play {
  background-image: url('./images/play-white.svg');
}

.react-video-full-screen .react-video-controls .pause {
  background-image: url('./images/pause-white.svg');
}

.react-video-full-screen .react-video-controls .volume {
  background-image: url('./images/volume-white.svg');
}

.react-video-full-screen .react-video-controls .no-volume {
  background-image: url('./images/no-volume-white.svg');
}

.react-video-full-screen .react-video-controls .full-screen {
  background-image: url('./images/full-screen-white.svg');
}

.react-video-full-screen .react-video-controls .time {
  color: #fff;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 35px;
  height: 35px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 28px;
  height: 28px;
  margin: 2px;
  border: 2px solid;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #bbb9b9 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 35px;
  height: 35px;
}
.lds-ripple div {
  position: absolute;
  border: 1px solid #868585;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 16px;
    left: 16px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 16px;
    left: 16px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 16px;
    left: 16px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    opacity: 0;
  }
}
